export class AdminRouteConstants {
	public static AUTH_MODULE_ROUTE = ""
	public static FEATURE_MODULE = "home"
	public static ORGANIZATIONS_MODULE = "organization"
	public static PROFILE_MODULE = "profile"
	public static REPORT_MODULE = "report"

	public static LOGIN = ""
	public static SIGN_UP = "sign-up"

	// Company settings Routes
	public static ORG_MANAGEMENT_ROUTE = ``
	public static ORG_MANAGEMENT = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.ORGANIZATIONS_MODULE}/${AdminRouteConstants.ORG_MANAGEMENT_ROUTE}`

	// Zt admin Profile Module Routes
	public static VIEW_PROFILE_ROUTE = ""
	public static VIEW_PROFILE = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.PROFILE_MODULE}/${AdminRouteConstants.VIEW_PROFILE_ROUTE}`

	// Zt admin Profile Module Routes
	public static ORGANIZATION_VIEW_PROFILE_ROUTE = "organization-details"
	public static ORGANIZATION_VIEW_PROFILE = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.PROFILE_MODULE}/${AdminRouteConstants.ORGANIZATION_VIEW_PROFILE_ROUTE}`

	// Client Report Routes
	public static CLIENT_REPORT_LIST_ROUTE = "client"
	public static CLIENT_REPORT_LIST = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.REPORT_MODULE}/${AdminRouteConstants.CLIENT_REPORT_LIST_ROUTE}`

	// Transaction Report Routes
	public static TRANSACTION_REPORT_LIST_ROUTE = "transaction"
	public static TRANSACTION_REPORT_LIST = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.REPORT_MODULE}/${AdminRouteConstants.TRANSACTION_REPORT_LIST_ROUTE}`

	// Flight Statistics Report Routes
	public static FLIGHT_STATISTICS_REPORT_LIST_ROUTE = "flight"
	public static FLIGHT_STATISTICS_REPORT_LIST = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.REPORT_MODULE}/${AdminRouteConstants.FLIGHT_STATISTICS_REPORT_LIST_ROUTE}`

	// Invoice Report Routes
	public static INVOICE_REPORT_LIST_ROUTE = "invoice"
	public static INVOICE_REPORT_LIST = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.REPORT_MODULE}/${AdminRouteConstants.INVOICE_REPORT_LIST_ROUTE}`

	// Service Wise Trips Report Routes
	public static SERVICE_WISE_TRIPS_REPORT_LIST_ROUTE = "service-wise-trip"
	public static SERVICE_WISE_TRIPS_REPORT_LIST = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.REPORT_MODULE}/${AdminRouteConstants.SERVICE_WISE_TRIPS_REPORT_LIST_ROUTE}`

	// Flight Data Charge Master Report Routes
	public static FLIGHT_DATA_CHARGE_MASTER_REPORT_LIST_ROUTE =
		"flight-data-charge-master"
	public static FLIGHT_DATA_CHARGE_MASTER_REPORT_LIST = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.REPORT_MODULE}/${AdminRouteConstants.FLIGHT_DATA_CHARGE_MASTER_REPORT_LIST_ROUTE}`

	// Hotel Statistics Report Routes
	public static HOTEL_STATISTICS_REPORT_ROUTE = "hotel-statistics"
	public static HOTEL_STATISTICS_REPORT = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.REPORT_MODULE}/${AdminRouteConstants.HOTEL_STATISTICS_REPORT_ROUTE}`

	// Hotel Data Charge Master Report Routes
	public static HOTEL_DATA_CHARGE_MASTER_REPORT_ROUTE =
		"hotel-data-charge-master"
	public static HOTEL_DATA_CHARGE_MASTER_REPORT = `${AdminRouteConstants.FEATURE_MODULE}/${AdminRouteConstants.REPORT_MODULE}/${AdminRouteConstants.HOTEL_DATA_CHARGE_MASTER_REPORT_ROUTE}`
}

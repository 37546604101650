/**
 *
 * Author: smartSense Consulting Solutions Pvt. Ltd.
 * Website: https://smartsensesolutions.com
 * Date: Sep 24 2018 11:30 AM
 * Copyright @ 2018 smartSense Consulting Solutions Pvt. Ltd.
 *
 */
import { Injectable } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"
import { Router } from "@angular/router"
import { SharedUserService } from "./shared-user.service"
import { EncryptionFunctions } from "../shared-functions/encryption-functions"
import { APPStorage } from "../shared-constants/admin-storage"
import { AdminRouteConstants } from "../shared-constants/admin-routes"
import { AppLogger } from "../shared-functions/common-functions"

@Injectable({
	providedIn: "root",
})
export class SharedService extends SharedUserService {
	private loaderCount = 0
	private _token = ""
	private _VToken = ""
	private _SCode = ""
	private _refreshToken = ""
	private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
		false,
	)
	private allowToResendOTP = 0
	private verifyEmailTimer = 0
	private OTPTimeout = 0
	private resendResponse: BehaviorSubject<any> = new BehaviorSubject<any>(
		null,
	)
	private submitCodeResponse: BehaviorSubject<any> = new BehaviorSubject<any>(
		null,
	)
	private isLoggedInUser: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false)
	private initiateTimer: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false)
	private initiateSnackBar: BehaviorSubject<string> =
		new BehaviorSubject<string>("")

	constructor(private router: Router) {
		super()
		AppLogger(`=====Shared service initialized=====`)
	}

	getTimer(): Observable<boolean> {
		return this.initiateTimer.asObservable()
	}

	setTimer(val: boolean): void {
		this.initiateTimer.next(val)
	}

	getSnackBar(): Observable<string> {
		return this.initiateSnackBar.asObservable()
	}

	setSnackBar(val: string): void {
		this.initiateSnackBar.next(val)
	}

	// return Observable of loading
	getLoader(): Observable<boolean> {
		return this.isLoading.asObservable()
	}

	setLoader(val: boolean): void {
		if (val) {
			this.loaderCount += 1
		} else {
			this.loaderCount -= 1
			if (this.loaderCount !== 0) {
				val = true
			}
		}
		this.isLoading.next(val)
	}

	getToken(): string {
		this._token = EncryptionFunctions.DECRYPT_OBJ(
			localStorage.getItem(APPStorage.TOKEN),
		)
		return this._token
	}

	setToken(value: string): void {
		localStorage.setItem(
			APPStorage.TOKEN,
			EncryptionFunctions.ENCRYPT_OBJ(value),
		)
		this._token = value
	}

	getResendOTP(): number {
		this.allowToResendOTP = EncryptionFunctions.DECRYPT_OBJ(
			sessionStorage.getItem(APPStorage.RESEND_OTP),
		)
		return this.allowToResendOTP
	}

	setResendOTP(value: number): void {
		sessionStorage.setItem(
			APPStorage.RESEND_OTP,
			EncryptionFunctions.ENCRYPT_OBJ(value),
		)
		this.allowToResendOTP = value
	}

	getVerifyPopUpTimer(): number {
		this.verifyEmailTimer = EncryptionFunctions.DECRYPT_OBJ(
			localStorage.getItem(APPStorage.VERIFY_EMAIL),
		)
		return this.verifyEmailTimer
	}

	setVerifyPopUpTimer(value: number): void {
		localStorage.setItem(
			APPStorage.VERIFY_EMAIL,
			EncryptionFunctions.ENCRYPT_OBJ(value),
		)
		this.verifyEmailTimer = value
	}

	getOTPTimeout(): number {
		this.OTPTimeout = EncryptionFunctions.DECRYPT_OBJ(
			sessionStorage.getItem(APPStorage.OTP_TIMEOUT),
		)
		return this.OTPTimeout
	}

	setOTPTimeout(value: number): void {
		sessionStorage.setItem(
			APPStorage.OTP_TIMEOUT,
			EncryptionFunctions.ENCRYPT_OBJ(value),
		)
		this.OTPTimeout = value
	}

	getVerificationToken(): string {
		this._VToken = EncryptionFunctions.DECRYPT_OBJ(
			localStorage.getItem(APPStorage.VERIFICATION_TOKEN),
		)
		return this._VToken
	}

	setVerificationToken(value: string): void {
		localStorage.setItem(
			APPStorage.VERIFICATION_TOKEN,
			EncryptionFunctions.ENCRYPT_OBJ(value),
		)
		this._VToken = value
	}

	/**
	 * for storing and retrieve refresh token
	 *
	 */
	getRefreshToken(): string {
		this._refreshToken = EncryptionFunctions.DECRYPT_OBJ(
			localStorage.getItem(APPStorage.REFRESH_TOKEN),
		)
		return this._refreshToken
	}

	setRefreshToken(value: string): void {
		localStorage.setItem(
			APPStorage.REFRESH_TOKEN,
			EncryptionFunctions.ENCRYPT_OBJ(value),
		)
		this._refreshToken = value
	}

	IsValidToken(token: string): boolean {
		return !!token
	}

	/*Shared User Token Param */
	isLoggedIn(): boolean {
		return this.IsValidToken(this.getToken())
	}

	getIsLoggedInUser(): Observable<boolean> {
		return this.isLoggedInUser.asObservable()
	}

	setIsLoggedInUser(val: boolean): void {
		this.isLoggedInUser.next(val)
	}

	setResendResponse = (body: any) => {
		this.resendResponse.next(body)
	}

	getResendResponse(): Observable<any> {
		return this.resendResponse.asObservable()
	}

	setSubmitCodeResponse = (body: any) => {
		this.submitCodeResponse.next(body)
	}

	getSubmitCodeResponse(): Observable<any> {
		return this.submitCodeResponse.asObservable()
	}

	setSignUpCode(value: string): void {
		localStorage.setItem(
			APPStorage.SIGN_UP_CODE,
			EncryptionFunctions.ENCRYPT_OBJ(value),
		)
		this._SCode = value
	}

	getSignUpCode(): string {
		this._SCode = EncryptionFunctions.DECRYPT_OBJ(
			localStorage.getItem(APPStorage.SIGN_UP_CODE),
		)
		return this._SCode
	}

	clearSession() {
		this.setSignUpCode("")
		this.setRefreshToken("")
		this.setToken("")
		this.setUser(null)
		this.setIsLoggedInUser(false)
		localStorage.clear()
		sessionStorage.clear()
	}

	logout(): void {
		this.clearSession()
		this.router.navigate(["/" + AdminRouteConstants.LOGIN])
	}
}

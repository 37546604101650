import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import {
	ConfirmMessageComponent,
	FormBaseComponent,
	NoDataComponent,
	PaginationComponent,
	SnackBarComponent,
} from "./shared-component"
import { FocusDirective } from "./shared-directive/focus.directive"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { HttpClientModule } from "@angular/common/http"
import { MaterialImportsModule } from "./shared-module/material-module-imports/org-material-imports"
import { HttpInterceptors } from "./http-interceptors/index-Interceptor"
import { CheckEmptyPipe, PlaceNAPipe } from "./pipes/checkEmpty.pipe"

@NgModule({
	declarations: [
		FormBaseComponent,
		PaginationComponent,
		SnackBarComponent,
		ConfirmMessageComponent,
		FocusDirective,
		NoDataComponent,
		CheckEmptyPipe,
		PlaceNAPipe,
	],
	imports: [
		CommonModule,
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		MaterialImportsModule,
	],
	exports: [
		FormsModule,
		HttpClientModule,
		ReactiveFormsModule,
		MaterialImportsModule,
		FormBaseComponent,
		PaginationComponent,
		SnackBarComponent,
		FocusDirective,
		ConfirmMessageComponent,
		NoDataComponent,
		CheckEmptyPipe,
		PlaceNAPipe,
	],
	providers: [HttpInterceptors],
	// entryComponents: [
	//   SnackBarComponent,
	//   ConfirmMessageComponent
	// ]
})
export class UtilityModule {}

<div class="login-container">
  <h2>Simplifying travel planning, from start to finish!</h2>

  <p>Welcome back, please login your account</p>

  <form [formGroup]="loginForm" (ngSubmit)="onLoginFormSubmit(loginForm)">
    <div class="form-control email-custom">
      <mat-form-field appearance="outline" class="custom-mat-form">
        <mat-label>Enter your email</mat-label>
        <input type="text" appAutofocus required #initialFormField formControlName="email" (blur)="onBlur(loginForm)" autocomplete="off" matInput placeholder="Email" class="email" />
        <mat-icon matPrefix svgIcon="email"></mat-icon>

        <mat-error *ngIf="isRequiredField(formControls['email'])">{{ validationMsg.EMAIL }}</mat-error>
        <mat-error *ngIf="isValidField(formControls['email'])">{{ validationMsg.EMAIL_VALID }}</mat-error>
        <mat-error *ngIf="isValidLength(formControls['email'])">{{ validationMsg.EMAIL_LENGTH }}</mat-error>
      </mat-form-field>
    </div>

    <div class="form-control">
      <mat-form-field appearance="outline" class="custom-mat-form">
        <mat-label>Enter your password</mat-label>
        <input [type]="hide ? 'password' : 'text'" formControlName="password" required (blur)="onBlur(loginForm)" autocomplete="off" matInput placeholder="Password" />
        <mat-icon matPrefix svgIcon="password"></mat-icon>
        <button tabindex="-5" type="button" class="password-show-hide-icon" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon svgIcon="{{ hide ? 'showPassword' : 'hidePassword' }}" class="custom-pw-icon"></mat-icon>
        </button>

        <mat-error *ngIf="isRequiredField(formControls['password'])">{{ validationMsg.PASSWORD }}</mat-error>
        <mat-error *ngIf="isValidField(formControls['password'])">{{ validationMsg.PASSWORD_VALID }}</mat-error>
        <mat-error *ngIf="isValidLength(formControls['password'])">{{ validationMsg.PASSWORD_LENGTH }}</mat-error>
      </mat-form-field>
    </div>

    <div>
      <a (click)="onForgotPassword()" class="forgot-password">Forgot password?</a>
    </div>

    <div class="MT-4">
      <button type="submit" mat-flat-button color="primary" [disabled]="showLoader" class="custom-login-btn">Log In</button>
    </div>
  </form>
</div>

import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import {
	CommonRegexp,
	ValidationConstant,
} from "../../../../utility/shared-constants/admin-validation"
import { FormBaseComponent } from "../../../../utility/shared-component"
import { SharedService } from "../../../../utility/shared-services/shared.service"
import { ForgotPasswordService } from "../../../services/forgot-password-flow/forgot-password.service"

@Component({
	selector: "app-otp",
	templateUrl: "./otp.component.html",
	styleUrls: ["./otp.component.scss"],
})
export class OtpComponent
	extends FormBaseComponent
	implements OnInit, OnDestroy
{
	otpForm!: FormGroup
	validationMsg = new ValidationConstant()

	@Output() resetPasswordClick = new EventEmitter()
	@Input() resendParams: { email: string } | null = null

	// Data related variables
	clockDisplay!: string
	interval: any
	duration!: number

	// State Variables
	showResendOTP?: boolean
	showResendOTPButton = true
	showLoader = false

	constructor(
		_fb: FormBuilder,
		private _router: Router,
		private _sharedService: SharedService,
		private _forgotPasswordService: ForgotPasswordService,
	) {
		super(_fb)
	}

	ngOnInit() {
		this.createOtpForm()
		this.startTimer(60 * +this._sharedService.getOTPTimeout())
	}

	ngOnDestroy() {
		if (this.interval) {
			this._sharedService.setOTPTimeout(0)
			this._sharedService.setResendOTP(0)
			clearInterval(this.interval)
		}
	}

	// To initialize FormGroup
	createOtpForm = () => {
		this.otpForm = this._fb.group({
			otp: [
				"",
				[
					<any>Validators.required,
					<any>Validators.pattern(CommonRegexp.NUMERIC_REGEXP),
					<any>Validators.minLength(6),
					<any>Validators.maxLength(6),
				],
			],
		})
	}

	sendVerification = () => {
		this._forgotPasswordService.generateOtp(this.resendParams).subscribe(
			(response) => {
				this._sharedService.setOTPTimeout(
					response["payload"]["timeout"],
				)
				this._sharedService.setVerificationToken(
					response["payload"]["token"],
				)
				if (this._sharedService.getOTPTimeout() > 0) {
					this.duration = 60 * +this._sharedService.getOTPTimeout()
					this.startTimer(this.duration)
					this.showResendOTP = false
				} else {
					this.showResendOTP = true
				}
			},
			(error) => {
				this.showResendOTPButton = true
			},
		)
	}

	onOtpFormSubmit = (form: FormGroup) => {
		if (this.onSubmit(form) && !this.showLoader) {
			this.showLoader = true
			this._forgotPasswordService.submitOtp(form.value).subscribe(
				(response) => {
					this._sharedService.setVerificationToken(
						response["payload"]["token"],
					)
					this.showLoader = false
					this.resetPasswordClick.emit(true)
				},
				() => {
					this.showLoader = false
				},
			)
		}
	}

	startTimer(duration: number) {
		if (duration > 0) {
			let timer: number = +duration
			let minutes: any
			let seconds: any
			const self = this
			self.interval = setInterval(function () {
				if (timer < 0) {
					clearInterval(self.interval)
					self.showResendOTPButton = true
					self.showResendOTP = true
					self.clockDisplay = ""
				} else if (timer === 0) {
					self._sharedService.setOTPTimeout(0)
					self._sharedService.setResendOTP(0)
					self.showResendOTP = true
					clearInterval(self.interval)
				} else {
					self._sharedService.setResendOTP(timer)
				}
				minutes = Math.floor(timer / 60)
				seconds = Math.floor(timer % 60)
				minutes = minutes < 10 ? "0" + minutes : minutes
				seconds = seconds < 10 ? "0" + seconds : seconds

				self.clockDisplay = minutes + ":" + seconds
				// console.log(timer);
				if (--timer < 0) {
					// console.log('duration 2');
					self.showResendOTPButton = true
					self.showResendOTP = true
					self.clockDisplay = ""
					clearInterval(self.interval)
				}
			}, 1000)
		}
	}

	/**
	 * convenience getter for easy access to form fields
	 */
	get formControls() {
		return this.otpForm.controls
	}
}

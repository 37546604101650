<div class="login-container">
  <h2>Reset your password</h2>

  <p>Please enter new password and confirm password.</p>

  <form [formGroup]="resetPasswordForm" (ngSubmit)="onResetPasswordFormSubmit(resetPasswordForm)">
    <div class="form-control">
      <mat-form-field appearance="outline">
        <mat-label>New password</mat-label>
        <input [type]="hide ? 'password' : 'text'" #initialFormField required formControlName="newPassword" autocomplete="off" matInput placeholder="Enter new password" />
        <mat-icon matPrefix svgIcon="password"></mat-icon>
        <button tabindex="-5" type="button" class="password-show-hide-icon" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon svgIcon="{{ hide ? 'showPassword' : 'hidePassword' }}"></mat-icon>
        </button>

        <mat-error *ngIf="isRequiredField(formControls['newPassword'])">{{ validationMsg.PASSWORD }}</mat-error>
        <mat-error *ngIf="isValidField(formControls['newPassword'])">{{ validationMsg.PASSWORD_VALID }}</mat-error>
        <mat-error *ngIf="isValidLength(formControls['newPassword'])">{{ validationMsg.PASSWORD_LENGTH }}</mat-error>
      </mat-form-field>
    </div>

    <div class="form-control">
      <mat-form-field appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input [type]="hideResetPassword ? 'password' : 'text'" (keydown.enter)="onHidePassword()" required formControlName="confirmPassword" autocomplete="off" matInput placeholder="Enter confirm Password" />
        <mat-icon matPrefix svgIcon="password"></mat-icon>
        <button tabindex="-6" class="password-show-hide-icon" mat-icon-button matSuffix (click)="hideResetPassword = !hideResetPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideResetPassword">
          <mat-icon svgIcon="{{ hideResetPassword ? 'showPassword' : 'hidePassword' }}"></mat-icon>
        </button>

        <mat-error *ngIf="isRequiredField(formControls['confirmPassword'])">{{ validationMsg.CONFIRM_PASSWORD }}</mat-error>
        <mat-error *ngIf="resetPasswordForm?.errors?.['doesMatchPassword']">
          {{ validationMsg.PASSWORD_DOESNT_MATCH }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="MT-4">
      <button type="submit" mat-flat-button color="primary" [disabled]="showLoader">Reset</button>
      <button (click)="onLoginRedirect()" class="ML-2" type="button" mat-stroked-button color="primary">Cancel</button>
    </div>
  </form>
</div>

<div class="otp-container">
  <h2>Enter 6 digit recovery code</h2>

  <p>
    We have sent an OTP to your email
    <span>"{{ resendParams?.email || "" }}"</span>
  </p>

  <form [formGroup]="otpForm" (ngSubmit)="onOtpFormSubmit(otpForm)">
    <div class="form-control">
      <mat-form-field appearance="outline">
        <mat-label>Enter one time password</mat-label>
        <input type="text" required #initialFormField (blur)="onBlur(otpForm)" formControlName="otp" autocomplete="off" matInput placeholder="OTP" />
        <mat-icon matPrefix svgIcon="password"></mat-icon>

        <mat-error *ngIf="isRequiredField(formControls['otp'])">{{ validationMsg.OTP }}</mat-error>
        <mat-error *ngIf="isValidField(formControls['otp'])">{{ validationMsg.OTP_VALID }}</mat-error>
        <mat-error *ngIf="isValidLength(formControls['otp'])">{{ validationMsg.OTP_LENGTH }}</mat-error>
      </mat-form-field>
    </div>

    <div class="MT-4">
      <button type="submit" mat-flat-button color="primary" [disabled]="showLoader">Verify</button>
      <button matStepperPrevious class="ML-2" type="button" mat-stroked-button color="primary">Back</button>
    </div>

    <div class="bottom-absolute">
      <span *ngIf="showResendOTP">If you don't receive code. <a class="primary-link" (click)="sendVerification()">Resend</a></span>
      <span *ngIf="!showResendOTP"
        >If you don't receive code. Resend in <label>{{ clockDisplay }}</label></span
      >
    </div>
  </form>
</div>

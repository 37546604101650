import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { UserAuthLoginContainerComponent } from "./components"
import { AdminRouteConstants } from "../utility/shared-constants/admin-routes"
import { AdminAuthGuard } from "../_guard"

const routes: Routes = [
	{
		path: AdminRouteConstants.LOGIN,
		component: UserAuthLoginContainerComponent,
		canActivate: [AdminAuthGuard],
	},
]

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class UserAuthRoutingModule {}

import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormControlDirective,
	FormControlName,
	FormGroup,
} from "@angular/forms"
import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	ViewChild,
	SimpleChanges,
} from "@angular/core"
import {
	MatSnackBar,
	MatSnackBarConfig,
	MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"

@Component({
	selector: "app-form-base",
	template: "",
})
export class FormBaseComponent implements OnChanges {
	@Input() canFocusField?: boolean
	@ViewChild("initialFormField", { static: true }) initialFormField:
		| ElementRef
		| undefined

	submitted = false

	constructor(protected _fb: FormBuilder) {}

	ngOnChanges() {
		if (this.initialFormField && this.canFocusField) {
			this.initialFormField.nativeElement.focus()
		}
	}

	/**
	 *
	 */
	protected createForm(controlsConfig: any, extraConfig = {}): FormGroup {
		const form = this._fb.group(controlsConfig, extraConfig)
		this.initializeFormFocus()
		return form
	}

	/**
	 *  Initialization methods for form focus
	 */

	private initializeFormFocus() {
		const originFormControlNgOnChanges =
			FormControlDirective.prototype.ngOnChanges
		FormControlDirective.prototype.ngOnChanges = function (
			this: FormControlDirective,
			changes: SimpleChanges,
		) {
			const valueAccessor = this.valueAccessor
			if (valueAccessor) {
				// @ts-ignore
				const elementRef =
					valueAccessor["_elementRef"] || valueAccessor["_element"]
				if (elementRef && elementRef.nativeElement) {
					const nativeElement: HTMLElement = elementRef.nativeElement
					nativeElement.focus()
				}
			}

			return originFormControlNgOnChanges.apply(this, [changes])
		}

		const originFormControlNameNgOnChanges =
			FormControlName.prototype.ngOnChanges
		FormControlName.prototype.ngOnChanges = function (
			this: FormControlDirective,
			changes: SimpleChanges,
		) {
			const result = originFormControlNameNgOnChanges.apply(this, [
				changes,
			])
			// @ts-ignore
			this.control.nativeElement =
			// @ts-ignore
			this.valueAccessor._elementRef
			// @ts-ignore
					? this.valueAccessor._elementRef.nativeElement
					: null
			return result
		}
	}

	/**
	 * On Submit form check validation method
	 * @param formName
	 * @param formControls
	 */
	protected onCheckValidation(formName: FormGroup) {
		this.focusSetOnInvalidControls(formName)
		this.controlForLoops(formName)
	}

	protected onSubmit(form: FormGroup) {
		this.submitted = true
		if (form.invalid) {
			this.onCheckValidation(form)
			return false
		}
		return true
	}

	/**
	 * @param formName
	 * @param formControls
	 */
	controlForLoops = (formName: FormGroup) => {
		for (const field in formName.controls) {
			const control = formName.get(field)
			if (control && control.invalid) {
				control.markAsTouched({ onlySelf: true })
				break
			}
		}
		return
	}

	/**
	 * Focus set on first invalid controls while click on submit event
	 * @param formName
	 */
	focusSetOnInvalidControls = (formName: FormGroup) => {
		const invalid = <FormControl[]>Object.keys(formName.controls)
			.map((key) => formName.controls[key])
			.filter((ctl) => ctl.invalid)

		if (invalid.length > 0) {
			for (let i = 0; i < invalid.length; i++) {
				invalid[i].markAsUntouched({ onlySelf: true })
			}
			const invalidElem: any = invalid[0]
			if (invalidElem.nativeElement) {
				invalidElem.nativeElement.focus()
			}
		}
	}

	/**
	 * Form Field Blur method
	 * @param formName
	 * @param formControls
	 */
	onBlur = (formName: FormGroup) => {
		/*
     const allControls = Object.keys(formName.controls)
        .map(key => {
            formName.controls[key].markAsUntouched({onlySelf: true});
            return {
                key: key,
                control: formName.controls[key],
            };
        });

    const currentIndex = allControls.findIndex((elem) => elem.key === formControlName);
    const invalidIndex = allControls.findIndex((elem) => elem.control.invalid);
    for (let i = 0; i < allControls.length; i++) {
        if (allControls[i].control.invalid && invalidIndex <= currentIndex) {
            allControls[i].control.markAsTouched({onlySelf: true});
            break;
        }
    }
    return;
    */

		for (const field in formName.controls) {
			const control = formName.get(field)
			control && control.markAsUntouched({ onlySelf: true })
		}
		return
	}

	/**
	 * @param formControlName(Required Field)
	 */
	isRequiredField(control: AbstractControl | null): boolean {
		if (!control) {
			return false
		}
		return control.touched && control.hasError("required")
	}

	/**
	 * @param formControlName(Valid field)
	 */
	isValidField(control: AbstractControl | null): boolean {
		if (!control) {
			return false
		}
		return control.touched && control.hasError("pattern")
	}

	isInvalidDateField = (formControlName: FormControl) => {
		return formControlName.touched && formControlName.invalid
	}

	getDatePickerErrors = (ctrl: FormControl) => {
		const errors = ctrl.errors
		if (errors) {
			const errorKeys = Object.keys(errors)
			if (errorKeys.length === 1) {
				return errorKeys[0]
			} else {
				return errorKeys[2]
			}
		}
		return ""
	}

	/**
	 * @param formControlName(Valid Length)
	 */
	// isValidLength = (formControlName: FormControl) => {
	//   return formControlName.touched && (formControlName.hasError('minlength') || formControlName.hasError('maxlength'));
	// };
	isValidLength(control: AbstractControl | null): boolean {
		if (!control) {
			return false
		}
		return (
			control.touched &&
			(control.hasError("minlength") || control.hasError("maxlength"))
		)
	}

	/**
	 * @param errorName
	 * @param formGroup
	 * @param formControl
	 * @param submitted
	 * Custom Validation method
	 */
	hasError = (
		errorName: string,
		formGroup: FormGroup,
		formControl: AbstractControl,
		submitted: boolean,
	): boolean => {
		return submitted && formGroup.hasError(errorName) && formControl.dirty
	}
}

import { Component, OnInit } from "@angular/core"
import { MatIconRegistry } from "@angular/material/icon"
import { DomSanitizer } from "@angular/platform-browser"
import {
	AUTH_FLOW_SVG,
	BASE_SVG,
	ORG_ADMIN_SVG,
	PROFILE_SVG,
	SEARCH_FLOW_SVG,
} from "../../../utility/shared-constants/admin-svg-icons"

@Component({
	selector: "app-svg-admin",
	template: "",
})
export class AdminSvgIconsComponent implements OnInit {
	BASE_DIR_PATH_AUTH_FLOW = "./assets/images/svg_files/auth-flow/"
	BASE_DIR_PATH_SEARCH_FLOW = "./assets/images/svg_files/search-flow/"
	BASE_DIR_PATH_ORG_ADMIN = "./assets/images/svg_files/org-admin/"
	BASE_DIR_PATH_PROFILE = "./assets/images/svg_files/profile-module/"
	BASE_DIR_PATH = "./assets/images/svg_files/"
	IMAGE_FORMAT = ".svg"

	constructor(
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
	) {}

	ngOnInit() {
		this.initializationMethod()
	}

	initializationMethod() {
		AUTH_FLOW_SVG.forEach((ImageName) => {
			this.setSvgAuthFlowImage(ImageName)
		})

		SEARCH_FLOW_SVG.forEach((ImageName) => {
			this.setSvgSearchFlowImage(ImageName)
		})

		ORG_ADMIN_SVG.forEach((ImageName) => {
			this.setSvgOrgFlowImage(ImageName)
		})

		PROFILE_SVG.forEach((ImageName) => {
			this.setSvgProfileImage(ImageName)
		})

		BASE_SVG.forEach((ImageName) => {
			this.setSvgImage(ImageName)
		})
	}

	setSvgAuthFlowImage = (ImageName: string) => {
		this.matIconRegistry.addSvgIcon(
			ImageName,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				`${this.BASE_DIR_PATH_AUTH_FLOW}${ImageName}${this.IMAGE_FORMAT}`,
			),
		)
	}

	setSvgSearchFlowImage = (ImageName: string) => {
		this.matIconRegistry.addSvgIcon(
			ImageName,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				`${this.BASE_DIR_PATH_SEARCH_FLOW}${ImageName}${this.IMAGE_FORMAT}`,
			),
		)
	}

	setSvgProfileImage = (ImageName: string) => {
		this.matIconRegistry.addSvgIcon(
			ImageName,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				`${this.BASE_DIR_PATH_PROFILE}${ImageName}${this.IMAGE_FORMAT}`,
			),
		)
	}

	setSvgOrgFlowImage = (ImageName: string) => {
		this.matIconRegistry.addSvgIcon(
			ImageName,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				`${this.BASE_DIR_PATH_ORG_ADMIN}${ImageName}${this.IMAGE_FORMAT}`,
			),
		)
	}

	setSvgImage = (ImageName: string) => {
		this.matIconRegistry.addSvgIcon(
			ImageName,
			this.domSanitizer.bypassSecurityTrustResourceUrl(
				`${this.BASE_DIR_PATH}${ImageName}${this.IMAGE_FORMAT}`,
			),
		)
	}
}

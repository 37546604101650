import { Injectable } from "@angular/core"
import { EncryptionFunctions } from "../shared-functions/encryption-functions"
import { APPStorage } from "../shared-constants/admin-storage"
import { AppLogger } from "../shared-functions/common-functions"

@Injectable({
	providedIn: "root",
})
export class SharedUserService {
	private _user: any

	constructor() {
		AppLogger(`=====Shared user initialized=====`)
	}

	getUser() {
		if (!this._user) {
			this._user = EncryptionFunctions.DECRYPT_OBJ(
				localStorage.getItem(APPStorage.USER),
				APPStorage.USER,
			)
		}
		return this._user
	}

	setUser(value: any): void {
		localStorage.setItem(
			APPStorage.USER,
			EncryptionFunctions.ENCRYPT_OBJ(value),
		)
		this._user = value
	}
}

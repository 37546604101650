import { Injectable } from "@angular/core"
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from "@angular/router"
import { SharedService } from "../utility/shared-services/shared.service"
import { AdminRouteConstants } from "../utility/shared-constants/admin-routes"

@Injectable({
	providedIn: "root",
})
export class AdminAuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private _sharedService: SharedService,
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): boolean {
		let activateRoute = true
		const readURL = state.url.split("?")[0]

		// redirect to login or course according to logged in status and current url
		if (this._sharedService.isLoggedIn()) {
			if (readURL === "/" + AdminRouteConstants.LOGIN) {
				this.router.navigate(["/" + AdminRouteConstants.ORG_MANAGEMENT])
				activateRoute = false
			}
		} else {
			if (readURL !== "/" + AdminRouteConstants.LOGIN) {
				this.router.navigate(["/" + AdminRouteConstants.LOGIN])
				activateRoute = false
			}
		}
		return activateRoute
	}
}

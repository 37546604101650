import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { PAGE_SIZE_OPTIONS } from "../../shared-constants/admin-base.constant"
import { SharedService } from "../../shared-services/shared.service"
import { MessageConstants } from "../../shared-constants/admin-message-constants"

@Component({
	selector: "app-pagination",
	templateUrl: "./pagination.component.html",
	styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
	// Angular related variables
	@Input() totalElements: number = 0
	@Input() rowNumber: number = 0
	@Input() recordsPerPage: number = 0
	@Output() recordChange: EventEmitter<any> = new EventEmitter()

	// Pagination related variables
	pageSizeOptions = PAGE_SIZE_OPTIONS

	constructor(private _sharedService: SharedService) {}

	ngOnInit() {}

	onClickGoToRecords = (value: any) => {
		const val = +value
		if (!val || val > this.totalElements || val < 1) {
			this._sharedService.setSnackBar(MessageConstants.VALID_NUMBER)
		} else {
			const params = {
				rowNumber: value,
				recordsPerPage: this.recordsPerPage,
			}
			this.recordChange.emit(params)
		}
	}

	onClickNext = () => {
		const params = {
			rowNumber: this.rowNumber + this.recordsPerPage,
			recordsPerPage: this.recordsPerPage,
		}
		this.recordChange.emit(params)
	}

	onClickPrevious = () => {
		const params = {
			rowNumber:
				this.rowNumber > this.recordsPerPage
					? this.rowNumber - this.recordsPerPage
					: 1,
			recordsPerPage: this.recordsPerPage,
		}
		this.recordChange.emit(params)
	}

	onChangePageSize = (value: any) => {
		const params = {
			rowNumber: this.rowNumber,
			recordsPerPage: +value,
		}
		this.recordChange.emit(params)
	}
}

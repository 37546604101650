export const AUTH_FLOW_SVG = [
	"email",
	"hidePassword",
	"password",
	"showPassword",
	"user",
	"bestOptionsEveryBudget",
	"confirm",
	"easyIntutiveInterface",
	"email-signup",
	"graphic",
	"hidePassword",
	"increasedEmployeeProductivity",
	"InteractiveDashboard",
	"lghtningFastBookings",
	"name",
	"number",
	"optimizedCorporateTravel",
	"organization",
	"otp",
	"password-signup",
	"process",
	"singletabReimbursement",
	"transparency",
	"unifiedPlatformForTravel",
	"signupActiveStep1",
	"signupActiveStep2",
	"signupActiveStep3",
	"signupActiveStep4",
	"signupActiveStep5",
	"signupActiveStep6",
]

export const SEARCH_FLOW_SVG = [
	"calendar",
	"dropdown",
	"hotel",
	"hotelTab",
	"planeFrom",
	"planeTab",
	"planeTo",
	"plus",
	"search",
	"swape",
	"rightChevron",
	"logout",
]

export const PROFILE_SVG = [
	"edit",
	"add",
	"vertical_menu",
	"pdf",
	"org_logo_placeholder",
	"profile_placeholder",
	"upload",
	"calendar",
	"card",
	"card2",
	"card_detail",
	"checkmark",
	"cross",
	"delete",
	"dropdown",
	"camera",
]

export const ORG_ADMIN_SVG = [
	"backArrow",
	"bulk",
	"checkmark",
	"clearField",
	"download",
	"dropdown",
	"dropdownPagination",
	"editField",
	"filter",
	"settings",
	"sortingDown",
	"sortingUp",
	"submenu1",
	"submenu2",
	"submenu3",
	"submenu4",
	"submenu5",
	"submenu6",
	"prevArrow",
]

export const BASE_SVG = ["noSearchResult"]

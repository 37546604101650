export class CommonRegexp {
	public static NUMERIC_REGEXP = "^[0-9]*$"
	public static ALPHA_NUMERIC_REGEXP = "^[A-Za-z0-9 ]*$"
	public static ALPHABETS_REGEXP = "^[A-Za-z ]*$"
	public static USER_NAME_REGEXP =
		"^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z\\d#?.!@$%^&*-]+$"
	public static EMAIL_ADDRESS_REGEXP =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	public static PASSWORD_REGEXP =
		/^(?=.*[a-zA-Z])(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\d#?!@$%^&*-]{8,}$/
}

export class ValidationConstant {
	public REQUIRED = ` is required`
	public VALID = `Please enter valid `

	public SELECT = `Select`
	public EMAIL = `Email` + this.REQUIRED
	public EMAIL_VALID = this.VALID + `email address`
	public EMAIL_LENGTH = `Email length between 6 to 50 characters`

	public OLD_PASSWORD = `Current password` + this.REQUIRED
	public OLD_PASSWORD_LENGTH = `Password length between 8 to 50 characters`

	public PASSWORD = `Password` + this.REQUIRED
	public PASSWORD_VALID = this.VALID + `password`
	public PASSWORD_LENGTH = `Password length between 8 to 50 characters`

	public CONFIRM_PASSWORD = `Confirm password` + this.REQUIRED
	public PASSWORD_DOESNT_MATCH = `Confirm password should be same as password`

	public OTP = `OTP` + this.REQUIRED
	public OTP_VALID = this.VALID + `OTP`
	public OTP_LENGTH = `OTP length 6 digits only`

	public ORG_NAME = `Organization name` + this.REQUIRED
	public ORG_NAME_LENGTH = `Organization name length between 4 to 100 characters`

	public FIRST_NAME = `First name` + this.REQUIRED
	public FIRST_VALID = `Please enter the valid First name`
	public FIRST_NAME_LENGTH = `First name length between 2 to 40 characters`

	public LAST_NAME = `Last name` + this.REQUIRED
	public LAST_VALID = `Please enter the valid Last name`
	public LAST_NAME_LENGTH = `Last name length between 2 to 40 characters`

	public MOBILE_NUMBER = `Mobile number` + this.REQUIRED
	public MOBILE_NUMBER_VALID = `Please enter a valid mobile number`
	public MOBILE_NUMBER_LENGTH = `Mobile number length between 7 to 13 digits`

	public ROLE = `Role` + this.REQUIRED

	public APPROVER = `Approver` + this.REQUIRED
}

/**
 *
 * Author: smartSense Consulting Solutions Pvt. Ltd.
 * Website: https://smartsensesolutions.com
 * Date: Sep 24 2018 11:30 AM
 * Copyright @ 2018 smartSense Consulting Solutions Pvt. Ltd.
 *
 */
import { Injectable } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { SharedService } from "./shared.service"
import { HttpHelperService } from "./http-helper.service"
import { Observable } from "rxjs"
import { HttpMethodsTypeEnum } from "../shared-constants/admin-base.constant"
import { AppLogger } from "../shared-functions/common-functions"

@Injectable({
	providedIn: "root",
})
export class APIManager extends HttpHelperService {
	constructor(_sharedService: SharedService, http: HttpClient) {
		super(_sharedService, http)
		AppLogger(`=====API manager initialized=====`)
	}

	/**
	 * method name : overridable httpHelperMethod
	 * purpose : handle loader, and call overload in parent class for getting Observable of response
	 * created : Sep 24 2018 11:30 AM
	 * Revision :
	 */
	override httpHelperMethod(
		methodType: HttpMethodsTypeEnum,
		url: string,
		params = {},
		httpOptions = this.Authorised_HttpOptions,
		showSnackBar: any,
		showLoader: any,
		searchParams = {},
		filesObj?: any,
	): Observable<any> {
		if (showLoader) {
			this._sharedService.setLoader(true)
		}
		if (
			methodType === HttpMethodsTypeEnum.POST_MULTIPART ||
			methodType === HttpMethodsTypeEnum.PUT_MULTIPART
		) {
			const formData = this.createFormDataObject(params, filesObj)
			params = formData
		}
		if (methodType === HttpMethodsTypeEnum.POST_MULTIPART_UNIQUE) {
			methodType = HttpMethodsTypeEnum.POST_MULTIPART
			const formData = this.createFormDataObjectUnique(params, filesObj)
			params = formData
		}
		return super.httpHelperMethod(
			methodType,
			url,
			params,
			httpOptions,
			showSnackBar,
			showLoader,
			searchParams,
			filesObj,
		)
	}

	getImage(imageUrl: string): Observable<Blob> {
		return this.http.get(imageUrl, { responseType: "blob" })
	}

	// TODO: Verify by calling API

	// return authorisation header
	get Authorised_HttpOptions() {
		const authToken = this._sharedService.getToken()
		const httpOptions = new HttpHeaders({
			Authorization: `Bearer ${authToken}`,
		})
		return { headers: httpOptions }
	}

	Authorised_SignUp_HttpOptions(authToken: string) {
		const httpOptions = new HttpHeaders({
			Authorization: `Bearer ${authToken}`,
		})
		return { headers: httpOptions }
	}

	Email_Verify_HttpOptions(token: any) {
		// const authToken = this._sharedService.getVerificationToken();
		const httpOptions = new HttpHeaders({
			"Verification-Token": token,
		})
		return { headers: httpOptions }
	}

	// return authorisation header with only content-type
	get Content_Type_HttpOptions() {
		const httpOptions = new HttpHeaders({
			"Content-Type": "application/json",
		})
		return { headers: httpOptions }
	}

	get FORGOT_PASSWORD_HttpOptions() {
		const authToken = this._sharedService.getVerificationToken()
		const httpOptions = new HttpHeaders({
			"Content-Type": "application/json",
			Token: `Bearer ${authToken}`,
		})
		return { headers: httpOptions }
	}

	// return authorisation header with content-type as x-www-form-urlencoded
	get Form_URL_Encoded_HttpOptions() {
		const authToken = this._sharedService.getToken()
		const httpOptions = new HttpHeaders({
			"Content-Type": "application/x-www-form-urlencoded",
			Authorization: `${authToken}`,
		})
		return { headers: httpOptions }
	}

	// return authorisation header with blob
	get Blob_HttpOptions(): any {
		const authToken = this._sharedService.getToken()
		return {
			headers: new HttpHeaders({
				Authorization: `Bearer ${authToken}`,
			}),
			responseType: "blob",
		}
	}

	// return authorisation header with blob
	get Blob_HttpOptions_image(): any {
		const authToken = this._sharedService.getToken()
		const httpOptions = new HttpHeaders({
			Authorization: `Bearer ${authToken}`,
		})
		return {
			headers: httpOptions,
			responseType: "blob",
		}
	}

	// return authorisation header with blob
	get Blob_HttpOptions_2(): any {
		const authToken = this._sharedService.getToken()
		return {
			headers: new HttpHeaders({}),
			responseType: "blob",
		}
	}

	/**
	 * return formData object from filesObject
	 */
	/*
  createFormDataObject = (obj, filesObj, form: FormData = null, namespace = '') => {
      // const formData = new FormData();
      let formData = form || new FormData();
      for (const obj of filesObj) {
          const imgFilesObj: File[] = obj['files'];
          for (let i = 0; i < imgFilesObj.length; i++) {
              formData.append(obj['reqKey'], imgFilesObj[i], imgFilesObj[i].name);
          }
      }
      if (obj && (Object.keys(obj).length)) {
          for (const propertyName in obj) {
              if (obj.hasOwnProperty(propertyName)) {
                  let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
                  if (typeof obj[propertyName] === 'object' && !(obj[propertyName] instanceof File)) {
                      this.createFormDataObject(obj[propertyName], [], formData, formKey);
                  } else {
                      formData.append(formKey, obj[propertyName]);
                  }
              }
          }
          return formData;
      }
  }

  */

	// /**
	//  * return formData object from filesObject
	//  */
	createFormDataObject = (params: any, filesObj: any) => {
		const formData = new FormData()
		for (const obj of filesObj) {
			const imgFilesObj: File[] = obj["files"]
			for (let i = 0; i < imgFilesObj.length; i++) {
				formData.append(
					obj["reqKey"],
					imgFilesObj[i],
					imgFilesObj[i].name,
				)
			}
		}
		if (params && Object.keys(params).length) {
			for (const docKey in params) {
				if (typeof params[docKey] === "object") {
					formData.append(docKey, JSON.stringify(params[docKey]))
				} else {
					formData.append(docKey, params[docKey])
				}
			}
		}
		return formData
	}

	createFormDataObjectUnique = (params: any, filesObj: any) => {
		const formData = new FormData()
		for (const obj of filesObj) {
			const imgFilesObj: File[] = obj["files"]
			for (let i = 0; i < imgFilesObj.length; i++) {
				// const param = {
				//     'email': obj['email'],
				//     'name': imgFilesObj[i].name
				// };
				const param = {} as { email: string; name: string }
				param["email"] = obj["email"]
				param["name"] = imgFilesObj[i].name
				formData.append(
					obj["reqKey"],
					imgFilesObj[i],
					JSON.stringify(param),
				)
			}
		}
		if (params && Object.keys(params).length) {
			for (const docKey in params) {
				if (typeof params[docKey] === "object") {
					formData.append(docKey, JSON.stringify(params[docKey]))
				} else {
					formData.append(docKey, params[docKey])
				}
			}
		}
		return formData
	}
}

import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"
import { AdminRouteConstants } from "./utility/shared-constants/admin-routes"
import { AdminAuthGuard } from "./_guard"

const routes: Routes = [
	{
		path: AdminRouteConstants.AUTH_MODULE_ROUTE,
		loadChildren: () =>
			import("./user-auth-module/user-auth.module").then(
				(m) => m.UserAuthModule,
			),
		canActivate: [AdminAuthGuard],
	},
	{
		path: AdminRouteConstants.FEATURE_MODULE,

		loadChildren: () =>
			import("./feature-module/feature.module").then(
				(m) => m.FeatureModule,
			),
		canActivate: [AdminAuthGuard],
	},
	{
		path: "**",
		redirectTo: AdminRouteConstants.LOGIN,
		pathMatch: "full",
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

import { environment } from "../../../environments/environment"
import { HttpMethodsTypeEnum } from "./admin-base.constant"

const BASE_URL = environment.apiUrl
const IDENTITY = "/identity"
const VERSION = "/api/v1"
const ORGANIZATION_API = "/organization/api/v1/organization"
const SUPER_ADMIN_API = "/organization/api/v1/superadmin"
const ORGANIZATION = "/organization"
const PUBLIC = "/public" + VERSION
const REPORTS = "/reports"
const TRIP = "/trip"
const TRIP_API = "/trip/api/v1"
const ORGANIZATION_CONVENIENCE_FEE_API = "/organization/api/v1"

export class AdminApi {
	static CREATE_ORGANIZATION_DATA(
		POST: HttpMethodsTypeEnum,
		CREATE_ORGANIZATION_DATA: any,
		params: any,
		Authorised_HttpOptions: {
			headers: import("@angular/common/http").HttpHeaders
		},
		arg4: boolean,
		arg5: boolean,
	): import("rxjs").Observable<any> {
		throw new Error("Method not implemented.")
	}
	// Authentication related
	public static LOGIN = BASE_URL + IDENTITY + "/login"
	public static LOGOUT = BASE_URL + IDENTITY + "/logout"
	public static CHANGE_PASSWORD = BASE_URL + "/identity/changepassword"

	// Forgot password flow
	public static FORGOT_PASSWORD_GENERATE_OTP =
		BASE_URL + IDENTITY + "/public/password/forgot"
	public static FORGOT_PASSWORD_SUBMIT_OTP =
		BASE_URL + IDENTITY + "/public/password/forgot/verify"
	public static FORGOT_PASSWORD_RESET_PASSWORD =
		BASE_URL + IDENTITY + "/public/password/reset"

	// Organization
	public static INVITE_ORGANIZATION = BASE_URL + ORGANIZATION_API + "/invite"
	public static ORGANIZATION_LIST = BASE_URL + ORGANIZATION_API + "/list"
	public static RESEND_ORGANIZATION_INVITE =
		BASE_URL + ORGANIZATION_API + "/invite/resend"
	public static ORGANIZATION_STATUS =
		BASE_URL + ORGANIZATION_API + "/profile/status/update"
	public static ORGANIZATION_DETAILS =
		BASE_URL + ORGANIZATION_API + "/profile/get"
	public static CREATING_ORGANISATION_DATA =
		BASE_URL + TRIP_API + "/settings/settings/createOrganization"

	public static UPDATING_ORGANISATION_DATA =
		BASE_URL + TRIP_API + "/settings/settings/:organizationId"
	public static GET_ALL_ORG_DATA = BASE_URL + TRIP_API + "/settings/allorg"
	public static GET_ORG_DATA_BY_ID = BASE_URL + TRIP_API + "/settings"

	public static ORGANIZATION_TYPE =
		BASE_URL + ORGANIZATION + PUBLIC + "/organization-types"
	public static ORGANIZATION_SIZE =
		BASE_URL + ORGANIZATION + PUBLIC + "/organization-size"
	public static INDUSTRY_LIST =
		BASE_URL + ORGANIZATION + PUBLIC + "/industry-list"

	public static COUNTRY_LIST =
		BASE_URL + ORGANIZATION + PUBLIC + "/country-list"
	public static STATE_LIST = BASE_URL + ORGANIZATION + PUBLIC + "/state-list"
	public static CITY_LIST = BASE_URL + ORGANIZATION + PUBLIC + "/city-list"

	//Super admin profile
	public static SUPER_ADMIN_PROFILE =
		BASE_URL + SUPER_ADMIN_API + "/profile/get"

	//Report
	public static CLIENT_REPORT_LIST =
		BASE_URL + ORGANIZATION + VERSION + REPORTS + "/client-report"
	public static CLIENT_REPORT_DOWNLOAD =
		BASE_URL + ORGANIZATION + VERSION + REPORTS + "/client-report/download"

	public static FIGHT_STATISTICS_REPORT_LIST =
		BASE_URL + TRIP + VERSION + REPORTS + "/flight-statistics"
	public static FIGHT_STATISTICS_REPORT_DOWNLOAD =
		BASE_URL + TRIP + VERSION + REPORTS + "/flight-statistics/download"

	public static TRANSACTION_REPORT_LIST =
		BASE_URL + TRIP + VERSION + REPORTS + "/transaction-data"
	public static TRANSACTION_REPORT_DOWNLOAD =
		BASE_URL + TRIP + VERSION + REPORTS + "/transaction-data/download"

	public static SERVICE_WISE_TRIPS_REPORT_LIST =
		BASE_URL + TRIP + VERSION + REPORTS + "/service-wise-trips"
	public static SERVICE_WISE_TRIPS_REPORT_DOWNLOAD =
		BASE_URL + TRIP + VERSION + REPORTS + "/service-wise-trips/download"

	public static INVOICE_REPORT_LIST =
		BASE_URL + TRIP + VERSION + REPORTS + "/customer-wise-invoice"
	public static INVOICE_REPORT_DOWNLOAD =
		BASE_URL + TRIP + VERSION + REPORTS + "/customer-wise-invoice/download"
	public static INVOICE_REPORT_BULK_DOWNLOAD =
		BASE_URL +
		TRIP +
		VERSION +
		REPORTS +
		"/customer-wise-invoice/download-invoices"

	public static HOTEL_STATISTICS_REPORT_LIST =
		BASE_URL + TRIP + VERSION + REPORTS + "/hotel-statistics"
	public static HOTEL_STATISTICS_REPORT_DOWNLOAD =
		BASE_URL + TRIP + VERSION + REPORTS + "/hotel-statistics/download"

	//Airports list
	public static AIRPORT_LIST = BASE_URL + TRIP + PUBLIC + "/airports"
	public static AIRLINES_LIST = BASE_URL + TRIP + PUBLIC + "/airlines"
	public static HOTEL_CITIES = BASE_URL + TRIP + VERSION + "/hotel/cities"

	public static HOTEL_DATA_CHARGE_MASTER_REPORT_LIST =
		BASE_URL + TRIP + VERSION + REPORTS + "/hotel-data-charge"
	public static HOTEL_DATA_CHARGE_MASTER_REPORT_DOWNLOAD =
		BASE_URL + TRIP + VERSION + REPORTS + "/hotel-data-charge/download"

	public static FLIGHT_DATA_CHARGE_MASTER_REPORT_LIST =
		BASE_URL + TRIP + VERSION + REPORTS + "/flight-data-charge"
	public static FLIGHT_DATA_CHARGE_MASTER_REPORT_DOWNLOAD =
		BASE_URL + TRIP + VERSION + REPORTS + "/flight-data-charge/download"

	// Services Settings

	public static UPDATING_SERVICE_DATA_BY_ID =
		BASE_URL + TRIP_API + "/services/putService"

		public static GETTING_INVENTORY_LIST =
			BASE_URL + TRIP_API + "/services/getServicesall"
	public static CREATING_FACILITYACCESS_DATA =
		BASE_URL + TRIP_API + "/services/updateFacilityAccess"
		public static GETTING_CHARGES_LIST =
		BASE_URL + ORGANIZATION_CONVENIENCE_FEE_API + "/convenience-fee/charges"
	public static UPDATING_CHARGESLIST_DATA =
		BASE_URL +
		ORGANIZATION_CONVENIENCE_FEE_API +
		"/convenience-fee/updateFee"
	public static WALLET_CREDIT = BASE_URL + TRIP_API + "/walletCredit"
	public static WALLET_CREDIT_BALANCE = BASE_URL + TRIP_API + "/walletCredit/balance"
	

}

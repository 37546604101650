import {
	Component,
	ElementRef,
	EventEmitter,
	OnInit,
	Output,
	Renderer2,
} from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { FormBaseComponent } from "../../../../utility/shared-component"
import {
	CommonRegexp,
	ValidationConstant,
} from "../../../../utility/shared-constants/admin-validation"
import { SharedService } from "../../../../utility/shared-services/shared.service"
import { ForgotPasswordService } from "../../../services/forgot-password-flow/forgot-password.service"

@Component({
	selector: "app-reset-password",
	templateUrl: "./reset-password.component.html",
	styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent
	extends FormBaseComponent
	implements OnInit
{
	resetPasswordForm: FormGroup = new FormGroup({})
	validationMsg = new ValidationConstant()

	// State variables
	hide = true
	hideResetPassword = true
	showLoader = false

	@Output() loginBtnClick = new EventEmitter()

	constructor(
		_fb: FormBuilder,
		private _router: Router,
		private _sharedService: SharedService,
		private _forgotPasswordService: ForgotPasswordService,
		elementRef: ElementRef,
		renderer: Renderer2,
	) {
		super(_fb)
	}

	ngOnInit() {
		this.createForgotPasswordForm()
	}

	createForgotPasswordForm = () => {
		this.resetPasswordForm = this._fb.group(
			{
				newPassword: [
					"",
					[
						<any>Validators.required,
						<any>Validators.pattern(CommonRegexp.PASSWORD_REGEXP),
						<any>Validators.minLength(8),
						<any>Validators.maxLength(50),
					],
				],
				confirmPassword: ["", [<any>Validators.required]],
			},
			{ validator: this.validate },
		)
	}

	onResetPasswordFormSubmit = (form: FormGroup) => {
		if (this.onSubmit(form) && !this.showLoader) {
			this.showLoader = true
			const params = { password: form.value["newPassword"] }
			this._forgotPasswordService.resetPassword(params).subscribe(
				() => {
					this._sharedService.setVerificationToken("")
					this.createForgotPasswordForm()
					this.showLoader = false
					this.loginBtnClick.emit(true)
				},
				() => {
					this.showLoader = false
				},
			)
		}
	}

	onHidePassword = () => {
		this.hideResetPassword = false
	}

	/**
	 * Confirm Validation Check
	 */
	validate(resetPasswordFormGroup: FormGroup) {
		const password = resetPasswordFormGroup.controls["newPassword"]
		const repeatPassword =
			resetPasswordFormGroup.controls["confirmPassword"]
		if (repeatPassword.value.length <= 0) {
			return null
		}
		if (password.value.length === 0) {
			return null
		}
		if (repeatPassword.value !== password.value) {
			repeatPassword.setErrors({ incorrect: true })
			return {
				doesMatchPassword: true,
			}
		}
		return null
	}

	onLoginRedirect = () => {
		this.loginBtnClick.emit(true)
	}

	/**
	 * convenience getter for easy access to form fields
	 */
	get formControls() {
		return this.resetPasswordForm ? this.resetPasswordForm.controls : {}
	}
}

import * as FileSaver from "file-saver"

export const isEmpty = (obj: object): boolean =>
	Object.keys(obj).length === 0 && obj.constructor === Object

export const AppLogger = (value: any) => {
	console.log(value)
}

export function isValidExcelAndCSVFile(filename: string): boolean {
	return (
		filename ===
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
		filename === "application/vnd.ms-excel" ||
		filename === "text/csv"
	)
}

export function isValidImageType(filename: string): boolean {
	return (
		filename === "image/png" ||
		filename === "image/jpeg" ||
		filename === "image/jpg"
	)
}

export function isValidImageOrPdfType(filename: string): boolean {
	return (
		filename === "image/png" ||
		filename === "image/jpeg" ||
		filename === "image/jpg" ||
		filename === "application/pdf"
	)
}

export function isValidPdfType(filename: string): boolean {
	return filename === "application/pdf"
}

export const saveFile = (response: any, fileName: string) => {
	FileSaver.saveAs(response, fileName)
}

export function removeEmptyFields(params: any) {
	for (const field of Object.keys(params)) {
		if (params.hasOwnProperty(field)) {
			if (Array.isArray(params[field])) {
				if (checkEmptyValue(params[field])) {
					delete params[field]
				} else {
					params[field].forEach((record: any) => {
						removeEmptyFields(record)
					})
					params[field] = params[field].filter(
						(value: any) => !checkEmptyValue(value),
					)
					if (checkEmptyValue(params[field])) {
						delete params[field]
					}
				}
			} else if (typeof params[field] === "object") {
				if (checkEmptyValue(params[field])) {
					delete params[field]
				} else {
					removeEmptyFields(params[field])
					if (checkEmptyValue(params[field])) {
						delete params[field]
					}
				}
			} else {
				if (checkEmptyValue(params[field])) {
					delete params[field]
				}
			}
		}
	}
	return params
}

function checkEmptyValue(value: any) {
	if (value === null || value === undefined) {
		return true
	} else if (typeof value === "string" && value === "") {
		return true
	} else if (Array.isArray(value) && value.length === 0) {
		return true
	} else if (
		value.constructor === Object &&
		Object.entries(value).length === 0
	) {
		return true
	}
	return false
}

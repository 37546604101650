import { BrowserModule } from "@angular/platform-browser"
import { NgModule } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { AppComponent } from "./app.component"
import { CommonModule } from "@angular/common"
import { UserAuthModule } from "./user-auth-module/user-auth.module"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { AppRoutingModule } from "./app-routing.module"
import { AdminSvgIconsComponent } from "./components/svg-import/admin-svg-icons/admin-svg-icons.component"
import { UtilityModule } from "./utility/utility.module"

@NgModule({
	declarations: [AppComponent, AdminSvgIconsComponent],
	imports: [
		CommonModule,
		BrowserModule,
		UtilityModule,
		UserAuthModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		FormsModule,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

<div class="app-section">
  <!-- <div class="app-section__left">
    <div class="app-section__left__one">
      <img class="app-section__left__one__small-img" src="assets/images/company-logos/ic_launcher.png" alt="">

      <div>
        <app-user-auth-slider></app-user-auth-slider>
      </div>
    </div>
  </div> -->

  <div class="app-section__right">
    <div class="app-section__right__container">
      <mat-horizontal-stepper (animationDone)="onAnimationFinish()" [linear]="isLinear" #stepper>
        <mat-step>
          <app-login [canFocusField]="!isAnimationOn && stepper.selectedIndex == 0" (forgotPasswordClick)="onNextStep(1)"></app-login>
        </mat-step>

        <mat-step>
          <app-forgot-password [canFocusField]="!isAnimationOn && stepper.selectedIndex == 1" (resendParams)="getResendApiParams($event)" (otpClick)="onNextStep(2)" *ngIf="stepper.selectedIndex == 1"></app-forgot-password>
        </mat-step>

        <mat-step>
          <app-otp [canFocusField]="!isAnimationOn && stepper.selectedIndex == 2" [resendParams]="resendApiParams" (resetPasswordClick)="onNextStep(3)" *ngIf="stepper.selectedIndex == 2"></app-otp>
        </mat-step>

        <mat-step>
          <app-reset-password [canFocusField]="!isAnimationOn && stepper.selectedIndex == 3" (loginBtnClick)="onNextStep(0)" *ngIf="stepper.selectedIndex == 3"></app-reset-password>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </div>
</div>

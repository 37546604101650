<div class="custom-pagination-section">
  <div class="custom-pagination-section__show-row">
    <span class="text-page">Rows per page:</span>
    <label class="custom-icon">
      <select #inputField (change)="onChangePageSize(inputField.value)">
        <option *ngFor="let option of pageSizeOptions">
          {{ option }}
        </option>
      </select>
    </label>
  </div>

  <div class="custom-pagination-section__go-to">
    <span class="text-page">Go to:</span>
    <input type="text" [value]="rowNumber" #inputField (keydown.enter)="onClickGoToRecords(inputField.value)" />
  </div>

  <div class="custom-pagination-section__details">
    <span class="text-page"> {{ rowNumber }} -
      {{ rowNumber + recordsPerPage - 1 < totalElements ? rowNumber + recordsPerPage - 1 : totalElements }} of {{
        totalElements }}</span>
  </div>

  <div class="custom-pagination-section__next-prev">
    <button matTooltip="Previous" mat-icon-button type="button" class="previous-next" [disabled]="rowNumber === 1"
      (click)="onClickPrevious()">
      <mat-icon svgIcon="prevArrow"></mat-icon>
    </button>

    <button matTooltip="Next" mat-icon-button type="button" class="previous-next previous-next--next"
      (click)="onClickNext()" [disabled]="rowNumber + recordsPerPage > totalElements">
      <mat-icon svgIcon="backArrow"></mat-icon>
    </button>
  </div>
</div>
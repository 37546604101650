import {
	AfterViewChecked,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
} from "@angular/core"
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar"
import { SharedService } from "./utility/shared-services/shared.service"

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
	title = "zt-frontend-admin"

	// Data related variables
	snackBarSubscriber$: any
	loaderSubscriber$: any

	// State related variable
	showLoader?: boolean

	constructor(
		private _snackBar: MatSnackBar,
		private cdRef: ChangeDetectorRef,
		private _sharedService: SharedService,
	) {}

	ngOnInit(): void {
		this.getSnackBarSubscriber()
		this.getLoaderSubscriber()
	}

	ngAfterViewChecked() {
		this.cdRef.detectChanges()
	}

	ngOnDestroy(): void {
		if (this.snackBarSubscriber$) {
			this.snackBarSubscriber$.unsubscribe()
		}
		if (this.loaderSubscriber$) {
			this.loaderSubscriber$.unsubscribe()
		}
	}

	getSnackBarSubscriber = () => {
		if (this.snackBarSubscriber$) {
			this.snackBarSubscriber$.unsubscribe()
		} else {
			this.snackBarSubscriber$ = this._sharedService
				.getSnackBar()
				.subscribe((message) => {
					if (message) {
						this.openSnackBar(message)
					}
				})
		}
	}

	getLoaderSubscriber = () => {
		if (this.loaderSubscriber$) {
			this.loaderSubscriber$.unsubscribe()
		} else {
			this.loaderSubscriber$ = this._sharedService
				.getLoader()
				.subscribe((flag) => {
					this.showLoader = flag
				})
		}
	}

	openSnackBar(message: string) {
		const configSnackBar = new MatSnackBarConfig()
		configSnackBar.verticalPosition = "bottom"
		configSnackBar.horizontalPosition = "left"
		configSnackBar.duration = 2000
		;(configSnackBar.panelClass = ["white-snackbar"]),
			this._snackBar.open(message, "Close", configSnackBar)
	}
}

import { NgModule } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatRippleModule } from "@angular/material/core"
import { MatStepperModule } from "@angular/material/stepper"
import { MatSelectModule } from "@angular/material/select"
import { MatAutocompleteModule } from "@angular/material/autocomplete"
import { MatMenuModule } from "@angular/material/menu"
import { MatTabsModule } from "@angular/material/tabs"
import { MatProgressBarModule } from "@angular/material/progress-bar"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatNativeDateModule } from "@angular/material/core"
import { MatCardModule } from "@angular/material/card"
import { MatDialogModule } from "@angular/material/dialog"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { MatSlideToggleModule } from "@angular/material/slide-toggle"
import { MatTooltipModule } from "@angular/material/tooltip"
import { MatRadioModule } from "@angular/material/radio"
import { MatTableModule } from "@angular/material/table"

@NgModule({
	imports: [
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatRippleModule,
		MatStepperModule,
		MatSelectModule,
		MatAutocompleteModule,
		MatMenuModule,
		MatTabsModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCardModule,
		MatDialogModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatTooltipModule,
		MatRadioModule,
		MatTableModule,
	],
	exports: [
		MatButtonModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatRippleModule,
		MatStepperModule,
		MatSelectModule,
		MatAutocompleteModule,
		MatMenuModule,
		MatTabsModule,
		MatProgressBarModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCardModule,
		MatDialogModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatTooltipModule,
		MatRadioModule,
		MatTableModule,
	],
	providers: [],
})
export class MaterialImportsModule {}

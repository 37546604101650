import { Injectable } from "@angular/core"
import { APIManager } from "../../../utility/shared-services/apimanager.service"
import { Observable } from "rxjs"
import { HttpMethodsTypeEnum } from "../../../utility/shared-constants/admin-base.constant"
import { AdminApi } from "../../../utility/shared-constants/admin-api"

@Injectable({
	providedIn: "root",
})
export class ForgotPasswordService {
	constructor(private _apiManager: APIManager) {}

	generateOtp(params: any): Observable<any> {
		return this._apiManager.httpHelperMethod(
			HttpMethodsTypeEnum.POST,
			AdminApi.FORGOT_PASSWORD_GENERATE_OTP,
			params,
			this._apiManager.Content_Type_HttpOptions,
			true,
			true,
		)
	}

	submitOtp(params: any): Observable<any> {
		return this._apiManager.httpHelperMethod(
			HttpMethodsTypeEnum.POST,
			AdminApi.FORGOT_PASSWORD_SUBMIT_OTP,
			params,
			this._apiManager.FORGOT_PASSWORD_HttpOptions,
			true,
			true,
		)
	}

	resetPassword(params: any): Observable<any> {
		return this._apiManager.httpHelperMethod(
			HttpMethodsTypeEnum.POST,
			AdminApi.FORGOT_PASSWORD_RESET_PASSWORD,
			params,
			this._apiManager.FORGOT_PASSWORD_HttpOptions,
			true,
			true,
		)
	}
}

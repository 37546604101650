import { NgModule } from "@angular/core"
import { UserAuthRoutingModule } from "./user-auth-routing.module"
import {
	ForgotPasswordComponent,
	LoginComponent,
	OtpComponent,
	ResetPasswordComponent,
	UserAuthLoginContainerComponent,
	UserAuthSliderComponent,
} from "./components"
import { CommonModule } from "@angular/common"
// import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
import { UtilityModule } from "../utility/utility.module"

@NgModule({
	declarations: [
		LoginComponent,
		ForgotPasswordComponent,
		OtpComponent,
		ResetPasswordComponent,
		UserAuthLoginContainerComponent,
		UserAuthSliderComponent,
	],
	exports: [],
	imports: [
		CommonModule,
		UtilityModule,
		// NgxUsefulSwiperModule,
		UserAuthRoutingModule,
	],
})
export class UserAuthModule {}

export enum HttpMethodsTypeEnum {
	GET = "get",
	POST = "post",
	PUT = "put",
	DELETE = "delete",
	PUT_MULTIPART = "putMultiPart",
	POST_MULTIPART = "postMultiPart",
	POST_MULTIPART_UNIQUE = "postMultiPartUnique",
}

export enum OrganizationRegistrationStatus {
	INVITED = 1,
	INVITE_REGISTER = 2,
	MANUAL_REGISTER = 3,
}

export enum OrganizationRegistrationStatusValue {
	INVITED = "Invited",
	INVITE_REGISTER = "Registered",
	MANUAL_REGISTER = "Self-Registered",
}

export class HttpStatus {
	public static SUCCESS = 200
	public static UNAUTHORIZED = 401
	public static EXPIRED = 450
}

export enum SortingEnum {
	ASCENDING = "ASC",
	DESCENDING = "DESC",
}

export const PAGE_SIZE_OPTIONS = [20, 50, 100]

export enum ReportTypeEnum {
	CSV = "csv",
	PDF = "pdf",
	EXCEL = "excel",
}

export enum ReportTypeExtensionEnum {
	CSV = "csv",
	PDF = "pdf",
	EXCEL = "xls",
}

export const ExportTypes = [
	{
		type: ReportTypeEnum.CSV,
		extension: ReportTypeExtensionEnum.CSV,
	},
	{
		type: ReportTypeEnum.PDF,
		extension: ReportTypeExtensionEnum.PDF,
	},
	{
		type: ReportTypeEnum.EXCEL,
		extension: ReportTypeExtensionEnum.EXCEL,
	},
]

// Transaction type List
export const TransactionTypeList = [
	{ id: "FLIGHT", value: "Flight" },
	{ id: "HOTEL", value: "Hotel" },
]

//payment mode enum
export enum MODE_OF_PAYMENT {
	DC = "Debit Card",
	CC = "Credit Card",
	NB = "NetBanking",
}

export const PaymentModeList = [
	{ id: "DC", value: "Debit Card" },
	{ id: "CC", value: "Credit Card" },
	{ id: "NB", value: "NetBanking" },
]

export const AMOUNT_CONDITIONS = [
	{ id: 1, value: "=" },
	{ id: 2, value: ">" },
	{ id: 3, value: "<" },
]

export enum BookingStatus {
	CREATED = "CREATED",
	BOOKED = "BOOKED",
	CANCELED = "CANCELED",
	EXPIRED = "EXPIRED",
}

export const BookingStatusList = [
	{ id: "CREATED", value: "Created" },
	{ id: "BOOKED", value: "Booked" },
	{ id: "CANCELED", value: "Canceled" },
	{ id: "EXPIRED", value: "Expired" },
]

export class APPStorage {
	public static TOKEN = "at"
	public static VERIFICATION_TOKEN = "vt"
	public static REFRESH_TOKEN = "rt"
	public static USER = "u"
	public static RESEND_OTP = "ro"
	public static VERIFY_EMAIL = "ve"
	public static OTP_TIMEOUT = "ot"
	public static SIGN_UP_CODE = "sc"
}

import {
	Component,
	ElementRef,
	EventEmitter,
	OnInit,
	Output,
	Renderer2,
} from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { FormBaseComponent } from "../../../utility/shared-component"
import {
	CommonRegexp,
	ValidationConstant,
} from "../../../utility/shared-constants/admin-validation"
import { AdminRouteConstants } from "../../../utility/shared-constants/admin-routes"
import { LoginService } from "../../services"
import { environment } from "../../../../environments/environment"
import { SharedService } from "../../../utility/shared-services/shared.service"

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends FormBaseComponent implements OnInit {
	// Angular Variables
	@Output() forgotPasswordClick = new EventEmitter()

	// FormGroup Variables
	loginForm!: FormGroup

	// Validation Constant
	validationMsg = new ValidationConstant()

	// State Variables
	hide = true
	showLoader = false

	constructor(
		_fb: FormBuilder,
		private _router: Router,
		private _loginService: LoginService,
		private _sharedService: SharedService,
		private _elementRef: ElementRef,
		private _renderer: Renderer2,
	) {
		super(_fb)
	}

	/**
	 * convenience getter for easy access to form fields
	 */
	get formControls() {
		return this.loginForm.controls
	}

	ngOnInit() {
		this.createLoginForm()
	}

	// To initialize FormGroup
	createLoginForm = () => {
		this.loginForm = this._fb.group({
			email: [
				"",
				[
					<any>Validators.required,
					<any>Validators.pattern(CommonRegexp.EMAIL_ADDRESS_REGEXP),
					<any>Validators.minLength(6),
					<any>Validators.maxLength(50),
				],
			],
			password: [
				"",
				[
					<any>Validators.required,
					<any>Validators.pattern(CommonRegexp.PASSWORD_REGEXP),
					<any>Validators.minLength(8),
					<any>Validators.maxLength(50),
				],
			],
		})
	}

	onLoginFormSubmit = (form: FormGroup) => {
		if (this.onSubmit(form) && !this.showLoader) {
			this.showLoader = true
			this._loginService
				.login({
					...form.value,
					appId: environment.userType,
				})
				.subscribe(
					(response) => {
						this.handleLoginResponse(response)
					},
					() => {
						this.showLoader = false
					},
				)
		}
	}

	handleLoginResponse = (response: any) => {
		const accessToken = response.payload.authResponse.accessToken
		const refreshToken = response.payload.authResponse.refreshToken
		this._sharedService.setToken(accessToken)
		this._sharedService.setRefreshToken(refreshToken)
		this.showLoader = false
		this._router.navigate(["/" + AdminRouteConstants.ORG_MANAGEMENT])
	}

	onForgotPassword = () => {
		this.forgotPasswordClick.emit(true)
	}
}

import { Injectable } from "@angular/core"
import { APIManager } from "../../../utility/shared-services/apimanager.service"
import { Observable } from "rxjs"
import { HttpMethodsTypeEnum } from "../../../utility/shared-constants/admin-base.constant"
import { AdminApi } from "../../../utility/shared-constants/admin-api"

@Injectable({
	providedIn: "root",
})
export class LoginService {
	constructor(private _apiManager: APIManager) {}

	login(params: any): Observable<any> {
		return this._apiManager.httpHelperMethod(
			HttpMethodsTypeEnum.POST,
			AdminApi.LOGIN,
			params,
			this._apiManager.Content_Type_HttpOptions,
			false,
			true,
		)
	}

	logout(): Observable<any> {
		return this._apiManager.httpHelperMethod(
			HttpMethodsTypeEnum.GET,
			AdminApi.LOGOUT,
			{},
			this._apiManager.Authorised_HttpOptions,
			false,
			true,
		)
	}
}

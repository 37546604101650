import { Component, OnInit, ViewChild } from "@angular/core"
import { MatStepper } from "@angular/material/stepper"

@Component({
	selector: "app-user-auth-login-container",
	templateUrl: "./user-auth-login-container.component.html",
	styleUrls: ["./user-auth-login-container.component.scss"],
})
export class UserAuthLoginContainerComponent implements OnInit {
	@ViewChild("stepper", { static: true }) stepper!: MatStepper

	isLinear = false
	isAnimationOn = true

	// Data related variables
	resendApiParams = null

	constructor() {}

	ngOnInit() {
		this.stepper.selectedIndex = 0
	}

	onNextStep = (index: number) => {
		this.stepper.selectedIndex = index
		this.isAnimationOn = true
	}

	getResendApiParams = (value: any) => {
		this.resendApiParams = value
	}

	onAnimationFinish = () => {
		this.isAnimationOn = false
	}
}

<div class="forgot-password-container">
  <h2>
    Can't remember your <br />
    password?
  </h2>

  <p>Not a problem! Just enter your registered email address below and we'll send you an OTP on your email.</p>

  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPasswordFormSubmit(forgotPasswordForm)">
    <div class="form-control">
      <mat-form-field appearance="outline">
        <mat-label>Enter your email</mat-label>
        <input type="text" required #initialFormField formControlName="email" (blur)="onBlur(forgotPasswordForm)" autocomplete="off" matInput placeholder="Email" />
        <mat-icon matPrefix svgIcon="email"></mat-icon>

        <mat-error *ngIf="isRequiredField(formControls['email'])">{{ validationMsg.EMAIL }}</mat-error>
        <mat-error *ngIf="isValidField(formControls['email'])">{{ validationMsg.EMAIL_VALID }}</mat-error>
        <mat-error *ngIf="isValidLength(formControls['email'])">{{ validationMsg.EMAIL_LENGTH }}</mat-error>
      </mat-form-field>
    </div>

    <div class="MT-4">
      <button type="submit" mat-flat-button color="primary" [disabled]="showLoader">Send OTP</button>
      <button matStepperPrevious class="ML-2" type="button" mat-stroked-button color="primary">Back</button>
    </div>
  </form>
</div>

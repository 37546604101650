import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { FormBuilder, FormGroup, Validators } from "@angular/forms"
import { Router } from "@angular/router"
import { FormBaseComponent } from "../../../../utility/shared-component"
import {
	CommonRegexp,
	ValidationConstant,
} from "../../../../utility/shared-constants/admin-validation"
import { environment } from "../../../../../environments/environment"
import { ForgotPasswordService } from "../../../services/forgot-password-flow/forgot-password.service"
import { SharedService } from "../../../../utility/shared-services/shared.service"

@Component({
	selector: "app-forgot-password",
	templateUrl: "./forgot-password.component.html",
	styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent
	extends FormBaseComponent
	implements OnInit
{
	forgotPasswordForm: FormGroup = new FormGroup({})
	validationMsg = new ValidationConstant()

	@Output() otpClick = new EventEmitter()
	@Output() resendParams = new EventEmitter()

	// State Variables
	showLoader = false

	constructor(
		_fb: FormBuilder,
		private _router: Router,
		private _forgotPasswordService: ForgotPasswordService,
		private _sharedService: SharedService,
	) {
		super(_fb)
	}

	ngOnInit() {
		this.createForgotPasswordForm()
	}

	// To initialize FormGroup
	createForgotPasswordForm = () => {
		this.forgotPasswordForm = this._fb.group({
			email: [
				"",
				[
					<any>Validators.required,
					<any>Validators.pattern(CommonRegexp.EMAIL_ADDRESS_REGEXP),
					<any>Validators.minLength(6),
					<any>Validators.maxLength(50),
				],
			],
		})
	}

	onForgotPasswordFormSubmit = (form: FormGroup) => {
		if (this.onSubmit(form) && !this.showLoader) {
			this.showLoader = true
			const params = { ...form.value, appTypeId: environment.userType }
			this._forgotPasswordService.generateOtp(params).subscribe(
				(response) => {
					this._sharedService.setOTPTimeout(
						response["payload"]["timeout"],
					)
					this._sharedService.setVerificationToken(
						response["payload"]["token"],
					)
					this.resendParams.emit(params)
					this.showLoader = false
					this.otpClick.emit(true)
				},
				() => {
					this.showLoader = false
				},
			)
		}
	}

	/**
	 * convenience getter for easy access to form fields
	 */
	get formControls() {
		return this.forgotPasswordForm.controls
	}
}
